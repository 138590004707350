.brand-box {
    padding: 16px;
    border: 2px solid transparent;
    background-image: linear-gradient(0deg, white 0%, white 100%),
    linear-gradient(0deg, #00AFEF 0%, #00AFEF 100%),
    linear-gradient(0deg, white 0%, white 100%),
    linear-gradient(0deg, white 0%, white 100%),
    linear-gradient(0deg, white 0%, white 100%);
    background-origin: padding-box, border-box, border-box, border-box, border-box;
    background-repeat: no-repeat;
    background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
    background-position: top left, top left, top right, bottom left, bottom right;
}